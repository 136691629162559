import { features } from "process";

export const constants = {
  apiUrl: `${process.env.REACT_APP_API_NEST_URL}`,
  tooltips: {
    upgradePlan: 'Upgrade your plan to access this content.',
  },
  // Added existing journals from cases
  journals: [
    'CLR',
    'KLR',
    'LHC',
    'MLD',
    'NLR',
    'PCRLJ',
    'PCTLR',
    'PLC',
    'PLD',
    'PLJ',
    'PSC',
    'PSC CIV',
    'PSC CRI',
    'PTCL',
    'PTD',
    'SCMR',
    'SCR',
    'TAX',
    'YLR'
  ],
  pageSize: localStorage.getItem('role') === 'free' ? '15' : '25',
  richTextEditorOptions: {
    options: ['inline', 'list', 'textAlign', 'history'], inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
  },
  regexes: {
    citationSearch: new RegExp(/((\d{4})(.?)((P.?T.?D)|(P.?C.?R.?.?.?L.?J)|(C.?L.?D)|(C.?L.?C)|(M.?L.?D)|(P.?T.?C.?L)|(S.?C.?M.?R)|(Y.?L.?R)|(L.?H.?C)|(P.?C.?T.?L.?R)|(.?P.?L.?C.?))(.?)((.?C.?S.?.?)|(.?Trib.?.?))?(.?)(\d{1,4}))|(((N.?L.?R.?)|(.?K.?L.?R.?)|(P.?L.?J.?)|(PLD|P(.?)L(.?)D)|(P.?C.?R.?.?.?L.?J)|(C.?L.?D)|(C.?L.?C)|(M.?L.?D)|(P.?T.?C.?L)|(S.?C.?M.?R)|(Y.?L.?R)|(L.?H.?C)|(P.?C.?T.?L.?R))(.?)(\d{4})(.?)(.?W.?.?P.?.?)?(.?)(Civil|Criminal|Labour|Revenue|Service.?|Tax|Civil Case.?|Labour and Service.? Case.?|Labour & Service.? Case.?|Criminal Case.?|Revenue Case.?|Shariat Case.?|Supreme Court Case.?|Tax and Corporate Case.?|Tax and Custom.? Case.?|Tax and Custom.?|Tax & Custom.?|S.?C.?|Supreme Court|High Court|Lahore|.?Lah..?|SHC|.?Sh.?C.?.?|Sindh|Kar.|Karachi|West Pakistan|Peshawar|Pesh.|F.?C.?|Federal Court|Dacca|Islamabad|Quetta|.?Rev.?.?|Privy Council|Baghdad-ul-Jadid)?(.?)(.?Pak.?.?|.?AJ.?K.?)?(.?)(\d{1,4}))/gi),
  },
  images: {
    gavel: require('./assets/gavel.jpg'),
    gavelDark: require('./assets/gavel-dark.jpg'),
  },
  svgs: {
    whiteBalance: require('./assets/svgs/balance-white.svg').default,
    eastLawLogo: require("./assets/svgs/eastlaw-logo.svg").default,
    eastLawLogoWhite: require("./assets/svgs/eastlaw-logo-white.svg").default,
    jazzCashLogo: require("./assets/jazzcash-logo.png"),
    masterCardLogo: require("./assets/svgs/mastercard-logo.svg").default,
    vizaCardLogo: require("./assets/svgs/visa-logo.svg").default,
    facebook: require("./assets/svgs/facebook.svg").default,
    linkedId: require("./assets/svgs/linkedin.svg").default,
    twitter: require("./assets/svgs/twitter.svg").default,
    instagram: require("./assets/svgs/instagram.svg").default,
    whatsapp: require("./assets/svgs/whatsapp.svg").default,
    rightArrow: require("./assets/svgs/right-arrow.svg").default,
    landingPageJudge: require("./assets/svgs/landing-page-judge.svg").default,
    judge: require("./assets/svgs/judge.svg").default,
    court: require("./assets/svgs/court.svg").default,
    statute: require("./assets/svgs/statute.svg").default,
    judgment: require("./assets/svgs/judgment.svg").default,
    departmentFiles: require("./assets/svgs/department-file.svg").default,
  },
  statuteTypes: [
    { id: 'primary', title: 'Primary' },
    { id: 'secondary', title: 'Secondary' },
    { id: 'bills', title: 'Bills' },
    { id: 'amendments', title: 'Amendments' },
  ],
  statuteSubGroups: [
    { title: 'Punjab' },
    { title: 'Federal' },
    { title: 'Sindh' },
    { title: 'Khyber Pakhtunkhwa' },
    { title: 'Balochistan' },
    { title: 'Gilgit Baltistan' },
    { title: 'AZAD JAMMU AND KASHMIR' },
  ],
  statuteGroups: [
    { title: 'Provincial' },
    { title: 'Federal' },
  ],
  eastlawBaseFeatures: [
    'Limitless Bookmarks',
    'EverSave Filters',
    'Unlimited Free text Searches allowed',
    'EastLaw Ultimate Access',
    'Around-the-Clock Support',
    'Futuristic Updates Included'
  ],
  eastlawCorporateFeatures: [
    'IP Based Access',
    'Unlimited Free text Searches allowed',
    'EastLaw Ultimate Access',
    'Around-the-Clock Support',
  ],
  blogCategories: [
    { id: 'latest-news', title: 'Latest News' },
    { id: 'high-courts', title: 'High Courts' },
    { id: 'supreme-courts', title: 'Supreme Courts' },
  ],
  bannerTypes: [
    { id: 'Promotion', title: 'Promotion' },
    { id: 'News', title: 'News' },
  ],
  applicationFeatures: [
    {
      title: 'Advanced Search and Sorting',
      description: 'With the power of Elastic Search, the largest legal database in Pakistan, at your fingertips, uncovering relevant results has never been easier. Our advanced search functionality allows you to search through legal judgments using keywords, with analytics and top results dynamically adjusting for relevance. You can also sort results by date or relevance for efficient browsing, saving you time and effort.',
      img: require('./assets/features/free-text-search.png'),
    },
    {
      title: 'Filters for Precision',
      description: 'Refining your search results is essential for precision in legal research. Our platform offers filters based on judge names, court hierarchies, and soon, practice areas. These filters dynamically adjust based on your selection criteria, allowing for precise customization and ensuring that you find exactly what you\'re looking for.',
      img: require('./assets/features/free-text-search-filters.png'),
    },
    {
      title: 'Extensive Data Coverage',
      description: 'Accessing a wide range of alternate journal citations and judgments from both reported and unreported sources is crucial for comprehensive legal research. Our integrated system covers judgments reported in over 17 journals across Pakistan, all searchable by citation, ensuring that you have access to a wealth of information.',
      img: require('./assets/features/search-by-citation.png'),
    },
    {
      title: 'Search by Judges\' Names',
      description: 'With the power of Elastic Search, the largest legal database in Pakistan, at your fingertips, uncovering relevant results has never been easier. Our advanced search functionality allows you to search through legal judgments using keywords, with analytics and top results dynamically adjusting for relevance. You can also sort results by date or relevance for efficient browsing, saving you time and effort.',
      img: require('./assets/features/search-by-judge.png'),
    },
    {
      title: 'Statutes and Their Referenced Judgments',
      description: 'Our platform offers a comprehensive collection of statutes, including primary and secondary statutes, departmental documents, and the latest bills and legislation. You can access rules, S.R.Os, notifications, circulars, and other departmental documents effortlessly, ensuring that you have access to all relevant legal provisions.',
      img: require('./assets/features/statute-search.png'),
    },
    {
      title: 'Latest Judgments and Sorted Archives',
      description: 'Our platform keeps you up-to-date with the latest judgments, seamlessly integrated into our system for every keyword you search. But we don\'t just stop there; you can also access archives sorted by date, allowing you to track legal developments over time. This feature is invaluable for staying informed about precedent-setting cases and understanding how legal interpretations evolve.',
      img: require('./assets/features/latest-judgments.png'),
    },
    {
      title: 'Personalized Dashboard',
      description: 'Save bookmarks or your searches for easy access on your personalized dashboard. Bookmark your most referred documents and searches for quick reference and enhanced productivity, allowing you to focus on your research without worrying about losing track of important information. Overall, our platform is designed to empower legal professionals with the tools they need to conduct thorough and efficient legal research, saving time and ensuring that you have access to the most relevant and up-to-date information.',
      img: require('./assets/features/personalized-dashboard.png'),
    },
  ],
  blogsCategoryMap: {
    'high-courts': 'High Courts',
    'latest-news': 'Latest News',
    'supreme-courts': 'Supreme Courts',
  },
  statuteTypeOptions: [
    { value: 'all', label: 'All' },
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'bills', label: 'Bills' },
    { value: 'amendment act', label: 'Amendments' },
  ]
};